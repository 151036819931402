<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <b-table
      :items="dataList"
      responsive
      :fields="tableColumns"
      class="mb-0"
    >

      <template #head(taxaAcerto)>
        <span>
          {{ $t('spot.meuAgente.taxaacerto') }}
          <feather-icon
            v-b-tooltip.hover.top="$t('spot.meuAgente.taxaacerto-tooltip')"
            icon="InfoIcon"
            class="text-primary"
          />
        </span>
      </template>

      <template #cell(nome)="data">
        <div class="d-flex align-items-center">
          <b-img
            width="90"
            rounded
            :src="getlogo(data.item.id)"
            alt="avatar img"
            class="m-2"
          />
          <div>
            <h4 class="font-weight-bolder">
              {{ data.item.nome }}
            </h4>
            <div class="font-small-4">
              {{ data.item.descricao }}
            </div>
            <div
              v-if="data.item.taxaDesempenho"
              class="font-small-4 text-muted"
            >
              {{ $t('spot.meuAgente.profitShare', { taxa_desempenho: data.item.taxaDesempenho }) }}
            </div>

          </div>
        </div>
      </template>

      <template #cell(taxaAcerto)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1 text-success">{{ data.item.sinalMetrica.totalGanhoPercent+'%' }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-small-4 text-muted">
            {{ data.item.sinalMetrica.total }} {{ $t('Sinais') }}
          </span>
        </div>
      </template>

      <template #cell(acao)="data">
        <b-button
          v-if="data.item.possuiAssinatura == true"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          block
          size="sm"
          variant="primary"
          @click.stop="editData(data.item.id)"
        >
          {{ $t('spot.meuAgente.configurarBtn') }}
        </b-button>

        <!-- Botão de seguir -->
        <b-button
          v-if="data.item.possuiAssinatura == false"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          v-b-modal.modal-assinar-agente
          block
          size="sm"
          class="mt-1"
          variant="success"
          @click.stop="modalSeguirAgente = data.item"
        >
          {{ $t('spot.meuAgente.SeguirBtn') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          block
          size="sm"
          class="mt-2"
          variant="primary"
          @click.stop="verOperacoes(data.item.id)"
        >
          {{ $t('spot.meuAgente.operacaoesBtn') }}
        </b-button>

      </template>

    </b-table>

    <b-modal
      v-if="modalSeguirAgente"
      id="modal-assinar-agente"
      ref="modal-assinar-agente"
      cancel-variant="outline-secondary"
      :ok-title="$t('spot.meuAgente.SeguirBtn')"
      :cancel-title="$t('futures.agente.assinante.sair')"
      :title="$t('spot.meuAgente.seguirAgente')"
      hide-header-close
      centered
      no-close-on-backdrop
      @ok="assinarAgente(modalSeguirAgente.id)"
    >
      <h2>{{ $t('spot.meuAgente.SeguirTextoConfirmacao') }} <strong>{{ modalSeguirAgente.nome }}</strong> ?</h2>
    </b-modal>

  </b-card>
</template>

<script>

import {
  BCard, BButton, VBTooltip, BTable, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import meuAgenteStoreModule from './meuAgenteStoreModule'

const MEU_AGENTE_SPOT_STORE_MODULE_NAME = 'meu-agente-spot'

export default {

  components: {
    BCard,
    BButton,
    BTable,
    BImg,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      dataList: [],
      modalSeguirAgente: null,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'nome', label: this.$i18n.t('spot.meuAgente.trader') },
        {
          key: 'taxaAcerto',
          label: `${this.$i18n.t('spot.meuAgente.taxaacerto')}`,
          tdClass: 'text-center',
        },
        {
          key: 'acao',
          label: this.$i18n.t('spot.meuAgente.acao'),
          thStyle: { width: '15%' },
        },
      ]
    },
  },

  created() {
    if (!store.hasModule(MEU_AGENTE_SPOT_STORE_MODULE_NAME)) store.registerModule(MEU_AGENTE_SPOT_STORE_MODULE_NAME, meuAgenteStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(MEU_AGENTE_SPOT_STORE_MODULE_NAME)) store.unregisterModule(MEU_AGENTE_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    getStatusColor(value) {
      let cortemp = ''
      if (value === 'Ativo') {
        cortemp = 'success'
      } else if (value === 'Inativo') {
        cortemp = 'danger'
      } else if (value === 'Expirado') {
        cortemp = 'danger'
      }
      return cortemp
    },

    assinarAgente(id) {
      store
        .dispatch('meu-agente-spot/assinarAgente', id)
        .then(() => {
          this.editData(id)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao assinar.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    editData(id) {
      this.$router.push(`/spot/meu-agente/${id}`).catch(() => {})
    },

    verOperacoes(id) {
      this.$router.push(`/spot/relatorio/sinal?agente=${id}&periodo=3m`).catch(() => {})
    },

    getlogo(id) {
      return `${process.env.VUE_APP_STATIC}agente/${id}/logo.png`
    },

    fetchList() {
      store
        .dispatch('meu-agente-spot/getMeuAgente')
        .then(response => {
          this.dataList = response.data
          // if (this.dataList.length === 1 && this.dataList[0].possuiAssinatura === true) {
          // this.$router.push({ name: 'spot-meu-agente-edit', params: { id: this.dataList[0].id } })
          // }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
